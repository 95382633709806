import {hasWhiteSpace, isString, validatePass} from "@/functions/Validations";

export default [
    [
        {
            col: 12,
            name: "register_name",
            customClass: "teste",
            value: "",
            type: "text",
            placeholder: "Ex.: Carlos Augusto",
            label: "Nome completo",
            leftIcon: "person",
            required: true,
            validateFunc: (val: any) => {
                if(!isString(val)) {
                    return false;
                }
                val = val.trim();
                if(val.length <= 7 || !hasWhiteSpace(val)){
                    return 'Nome inválido';
                }
                return true;
            }
        }
    ],
    [
        {
            col: 12,
            name: "register_email",
            customClass: "teste",
            value: "",
            type: "email",
            placeholder: "Ex.: nome@exemplo.com",
            label: "E-mail",
            leftIcon: "mail_outline",
            required: true
        }
    ],
    [
        {
            col: 12,
            name: "register_email_confirma",
            customClass: "teste",
            value: "",
            type: "email",
            placeholder: "Ex.: nome@exemplo.com",
            label: "Confirme o E-mail",
            leftIcon: "mail_outline",
            validateFunc: (val: any, item: any, all: any) => {
                if(val !== all['register_email']){
                    return 'E-mails diferentes';
                }
                return true;
            }
        }
    ],
    [
        {
            col: 6,
            name: "register_pass",
            value: "",
            type: "password",
            placeholder: "Ex: *******",
            label: "Senha",
            leftIcon: "key",
            required: true,
            validateFunc: validatePass
        },
        {
            col: 6,
            name: "register_pass_confirma",
            value: "",
            type: "password",
            placeholder: "Ex: *******",
            label: "Confirme a Senha",
            leftIcon: "key",
            required: true,
            validateFunc: (val: any, item: any, all: any) => {
                if(val !== all['register_pass']){
                    return 'Senhas diferentes';
                }
                return true;
            }
        }
    ]
]