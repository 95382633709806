import {FetchWs} from "@/functions/FetchWs";

export async function submitFormNew(data: any, vue: any)
{
    console.log(data)
    const body = new FormData();
    body.append("usuario", data.usu_name);
    body.append("email", data.usu_email);
    body.append("senha", data.usu_pass);
    const response = await FetchWs('user/register', 'POST',body);
    if(response?.success) {
        await vue.$router.push({name: 'usuarios'});
    }

}