import {validatePass} from "@/functions/Validations";

export default [
    [
        {
            col: 12,
            name: "new_pass",
            value: "",
            type: "password",
            placeholder: "Ex: *******",
            label: "Nova Senha",
            leftIcon: "key",
            required: true,
            validateFunc: validatePass
        },
        {
            col: 12,
            name: "new_pass_confirma",
            value: "",
            type: "password",
            placeholder: "Ex: *******",
            label: "Confirme a Senha",
            leftIcon: "key",
            required: true,
            validateFunc: (val: any, item: any, all: any) => {
                if(val !== all['new_pass']){
                    return 'Senhas diferentes';
                }
                return true;
            }
        }
    ]

]