export default {
    card:{
        lg: 12,
        xl: 12
    },
    listTable: {
        name: "table-clientes",
        endpoint: "cliente/get-all",
        idKey:'cem_id',
        novoModal: true,
        novoText: 'Novo',
        columns: [
            { key: 'acoes_id', label: 'Ações', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
            { key: 'cem_situacao', label: 'Situaçao', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
            { key: 'cem_agencia', label: 'Agência', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
            { key: 'cem_segmento', label: 'Segmento', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
            { key: 'cem_consultor', label: 'Consultor', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
            { key: 'cem_documento', label: 'Documento', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
            { key: 'cem_tipo_pessoa', label: 'Pessoa', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
            { key: 'cem_contaazul_code', label: 'ContaAzul Code', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
            { key: 'cem_nome_fantasia', label: 'Nome Fantasia', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
            { key: 'cem_razao_social', label: 'Razao Social', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
            { key: 'cem_link_drive', label: 'Drive', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
            { key: 'cem_endereco_logradouro', label: 'End. Logradouro', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
            { key: 'cem_endereco_numero', label: 'End. Nº', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
            { key: 'cem_endereco_complemento', label: 'End. Complemento', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
            { key: 'cem_endereco_bairro', label: 'Bairro', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
            { key: 'cem_endereco_cep', label: 'CEP', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
            { key: 'cem_endereco_cidade', label: 'Cidade', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
            { key: 'cem_endereco_estado', label: 'Estado', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
            { key: 'cem_obs', label: 'OBS', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
            { key: 'cem_data_criacao', label: 'Criação', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
            { key: 'cem_data_update', label: 'Ult. Alteração', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
            { key: 'cem_qtd_chamados', label: 'Chamados no Mês', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        ],
        columnsSelecteds:[],
        columnsSelectedsKeys: {
            acoes_id: true,
            cem_situacao: true,
            cem_agencia: true,
            cem_nome_fantasia: true,
            cem_link_drive: true,
            cem_data_criacao: true,
        }
    },
    newForm: {
        data: {
            closeButton: true,
            endpoint: 'cliente/new'
        },
        struct: [
            [
                {
                    col: 12,
                    name: "cem_nome_fantasia",
                    value: "",
                    type: "text",
                    label: "Nome Fantasia",
                    leftIcon: "store",
                },
            ],
            [
                {
                    col: 12,
                    name: "cem_razao_social",
                    value: "",
                    type: "text",
                    label: "Razao social",
                    leftIcon: "store",
                }
            ],
            [
                {
                    col: 4,
                    name: "cem_situacao",
                    value: "",
                    type: "select",
                    options: ["Ativo", "Pendente", "Inadimplente", "Cancelado"],
                    label: "Situação",
                    required: true,
                    leftIcon: "fact_check",
                },
                {
                    col: 4,
                    name: "cem_agencia",
                    value: "",
                    options: ["Alper", "Promover"],
                    type: "select",
                    label: "Agência",
                    leftIcon: "home_work",
                    required: true
                },
                {
                    col: 4,
                    name: "cem_consultor",
                    value: "",
                    type: "text",
                    label: "Consultor",
                    leftIcon: "account_box",
                },
            ],
            [
                {
                    col: 4,
                    name: "cem_segmento",
                    value: "",
                    type: "text",
                    label: "Segmento",
                    leftIcon: "segment",
                },
                {
                    col: 4,
                    name: "cem_tipo_pessoa",
                    value: "",
                    options: ["PF", "PJ"],
                    type: "select",
                    label: "Tipo Pessoa",
                    leftIcon: "corporate_fare",
                },
                {
                    col: 4,
                    name: "cem_documento",
                    value: "",
                    type: "text",
                    label: "Documento",
                    leftIcon: "contact_page",
                }
            ],
            [
                {
                    col: 12,
                    name: "cem_endereco_logradouro",
                    value: "",
                    type: "text",
                    label: "Logradouro",
                    leftIcon: "place",
                }
            ],
            [
                {
                    col: 4,
                    name: "cem_endereco_numero",
                    value: "",
                    type: "text",
                    label: "numero",
                    leftIcon: "place",
                },
                {
                    col: 4,
                    name: "cem_endereco_complemento",
                    value: "",
                    type: "text",
                    label: "Complemento",
                    leftIcon: "place",
                },
                {
                    col: 4,
                    name: "cem_endereco_bairro",
                    value: "",
                    type: "text",
                    label: "Bairro",
                    leftIcon: "place",
                }
            ],
            [
                {
                    col: 4,
                    name: "cem_endereco_cep",
                    value: "",
                    type: "text",
                    label: "CEP",
                    leftIcon: "home",
                },
                {
                    col: 4,
                    name: "cem_endereco_cidade",
                    value: "",
                    type: "text",
                    label: "Cidade",
                    leftIcon: "place",
                },
                {
                    col: 4,
                    name: "cem_endereco_estado",
                    value: "",
                    type: "text",
                    label: "Estado",
                    leftIcon: "place",
                }
            ],
            [
                {
                    col: 12,
                    name: "cem_link_drive",
                    value: "",
                    type: "text",
                    label: "Link Drive",
                    leftIcon: "add_to_drive",
                }
            ],
            [
                {
                    col: 12,
                    name: "cem_obs",
                    value: "",
                    type: "textarea",
                    label: "Obs",
                    leftIcon: "keyboard",
                }
            ]
        ]
    },
    editForm: {
        data: {
            closeButton: true,
            endpoint: 'cliente/update/:id'
        },
        struct: [
            [
                {
                    col: 12,
                    name: "cem_nome_fantasia",
                    value: "",
                    type: "text",
                    label: "Nome Fantasia",
                    leftIcon: "store",
                },
            ],
            [
                {
                    col: 6,
                    name: "cem_razao_social",
                    value: "",
                    type: "text",
                    label: "Razao social",
                    leftIcon: "store",
                },
                {
                    col: 6,
                    name: "cem_qtd_chamados",
                    value: "",
                    type: "text",
                    label: "Chamados no Mês",
                    leftIcon: "support_agent",
                    readonly: true
                }
            ],
            [
                {
                    col: 4,
                    name: "cem_situacao",
                    value: "",
                    type: "select",
                    options: ["Ativo", "Pendente", "Inadimplente", "Cancelado"],
                    label: "Situação",
                    required: true,
                    leftIcon: "fact_check",
                },
                {
                    col: 4,
                    name: "cem_agencia",
                    value: "",
                    options: ["Alper", "Promover"],
                    type: "select",
                    label: "Agência",
                    leftIcon: "home_work",
                    required: true
                },
                {
                    col: 4,
                    name: "cem_consultor",
                    value: "",
                    type: "text",
                    label: "Consultor",
                    leftIcon: "account_box",
                },
            ],
            [
                {
                    col: 4,
                    name: "cem_segmento",
                    value: "",
                    type: "text",
                    label: "Segmento",
                    leftIcon: "segment",
                },
                {
                    col: 4,
                    name: "cem_tipo_pessoa",
                    value: "",
                    options: ["PF", "PJ"],
                    type: "select",
                    label: "Tipo Pessoa",
                    leftIcon: "corporate_fare",
                },
                {
                    col: 4,
                    name: "cem_documento",
                    value: "",
                    type: "text",
                    label: "Documento",
                    leftIcon: "contact_page",
                }
            ],
            [
                {
                    col: 12,
                    name: "cem_endereco_logradouro",
                    value: "",
                    type: "text",
                    label: "Logradouro",
                    leftIcon: "place",
                }
            ],
            [
                {
                    col: 4,
                    name: "cem_endereco_numero",
                    value: "",
                    type: "text",
                    label: "numero",
                    leftIcon: "place",
                },
                {
                    col: 4,
                    name: "cem_endereco_complemento",
                    value: "",
                    type: "text",
                    label: "Complemento",
                    leftIcon: "place",
                },
                {
                    col: 4,
                    name: "cem_endereco_bairro",
                    value: "",
                    type: "text",
                    label: "Bairro",
                    leftIcon: "place",
                }
            ],
            [
                {
                    col: 4,
                    name: "cem_endereco_cep",
                    value: "",
                    type: "text",
                    label: "CEP",
                    leftIcon: "home",
                },
                {
                    col: 4,
                    name: "cem_endereco_cidade",
                    value: "",
                    type: "text",
                    label: "Cidade",
                    leftIcon: "place",
                },
                {
                    col: 4,
                    name: "cem_endereco_estado",
                    value: "",
                    type: "text",
                    label: "Estado",
                    leftIcon: "place",
                }
            ],
            [
                {
                    col: 12,
                    name: "cem_link_drive",
                    value: "",
                    type: "text",
                    label: "Link Drive",
                    leftIcon: "add_to_drive",
                }
            ],
            [
                {
                    col: 12,
                    name: "cem_obs",
                    value: "",
                    type: "textarea",
                    label: "Obs",
                    leftIcon: "keyboard",
                }
            ]
        ]
    }
}