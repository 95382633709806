<template>
  <monta-crud :Crud="Crud">
    <template #modalAditionalActions="{ acoes_id: acoes_id }">
      <div>
        <va-button class="btn-dropdown" icon-right="history" color="#8338ec" gradient size="medium" @click="historico = acoes_id">Histórico</va-button>
        <br>
        <va-button class="btn-dropdown" icon-right="edit" color="info" gradient size="medium" @click="$router.push({name: 'clientes-contatos', params:{id: acoes_id}})">Contatos</va-button>
        <br>
        <va-button class="btn-dropdown" icon-right="edit" color="info" gradient size="medium" @click="$router.push({name: 'clientes-servicos', params:{id: acoes_id}})">Serviços</va-button>
      </div>
    </template>
  </monta-crud>
  <historico v-if="historico" :id="historico" v-on:close="historico = false"/>
</template>

<script>
  import Crud from "./Crud";
  import Historico from "./Historico";

  export default {
    components: {Historico},
    data() {
      return {
        Crud,
        historico: false
      }
    }
  }
</script>

<style scoped>
.btn-dropdown{
  min-width: 165px;
  margin-bottom: 3px;
}
</style>