<template>
  <div class="row justify-center align-center">
    <va-card class="flex xs12 sm12 md6 lg4 xl4">
      <va-card-content>
        <monta-form :form="form" @complete="(data)=>submitFormNew(data, this)" />
      </va-card-content>
    </va-card>
  </div>
</template>

<script>
import MontaForm from "@/components/monta-form/MontaForm";
import FormStruct from "./NovoUsuarioFormStruct"
import {submitFormNew} from "./NovoUsuarioFunctions"

export default {
  name: "NovoUsuario",
  components: {MontaForm},
  data() {
    return {
      form: {
        struct: FormStruct
      },
    }
  },
  methods:{
    submitFormNew
  }
}
</script>

<style scoped>

</style>