<template>
  <div class="row justify-center align-center">
    <va-card class="flex xs12 sm12 md6 lg4 xl4">
      <va-card-content>
        <monta-form v-if="formCarregado" :key="formCarregado" :form="form" @complete="(data)=>submitFormUpdate(data, this)"/>
      </va-card-content>
    </va-card>
  </div>
</template>

<script>
import MontaForm from "@/components/monta-form/MontaForm"
import {carregaDadosParaFormUpdate, submitFormUpdate, getUsuario} from "./EditarUsuarioFunctions"

export default {
  name: "EditarUsuario",
  components: {MontaForm},
  props:['id'],
  async mounted() {
    this.carregaDadosParaFormUpdate(this)
    await getUsuario(this.idUser);
  },
  data() {
    return {
      form: {},
      formCarregado: 0,
      idUser: this.$route.params.id
    }
  },
  methods:{
    carregaDadosParaFormUpdate,
    submitFormUpdate
  },
}
</script>

<style scoped>

</style>