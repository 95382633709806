<template>
  <va-modal
    v-if="loaded"
    v-model="loaded"
    size="large" 
    @clickOutside="$emit('close')"
    @close="$emit('close')"
    @cancel="$emit('close')"
    @ok="$emit('close')"
  >
    <va-data-table
      :items="data"
      :columns="columns"
    >
      <template #cell(lcl_dados)="{ source: lcl_dados }">
        <div v-for="lcl_dado in JSON.parse(lcl_dados)" :key="lcl_dado">
          - {{ lcl_dado }}
        </div>
      </template>
      <template #cell(usu_name)="{ source: usu_name }">
        {{ usu_name ?? 'Robô' }}
      </template>
    </va-data-table>
  </va-modal>
</template>

<script>
import {FetchWs} from "@/functions/FetchWs";

export default {
  name: "Historico",
  props:['id'],
  data(){
    return {
      loaded: false,
      data: [],
      columns: [
        { key: "lcl_lrq_id", label: 'Req ID' },
        { key: "usu_name", label: 'Usuário' },
        { key: "lcl_dados", label: 'Dados' },
        { key: "lcl_data_cad", label: "Data" }
      ]
    }
  },
  mounted(){
    this.load();
  },
  methods:{
    async load(){
      const response = await FetchWs('cliente/get-historic/' + this.id);
      if(response && response.success){
        this.data = response.data;
        console.log(this.data)
        this.loaded = true;
      }
    }
  }
}
</script>

<style>

</style>