import {FetchWs} from "@/functions/FetchWs";

export async function changePass(data:any, vue: any): Promise<void>
{
    const body = new FormData();
    body.append("id", vue.idUser);
    body.append("senha", data.new_pass);
    const response = await FetchWs('user/change-pass', 'POST',body);
    if(response?.success) {
        vue.$router.back()
    }
}