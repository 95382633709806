<template>
  <monta-form :form="formSignup" @complete="submitFormSignup"/>
</template>

<script>
import FormSignupStruct from "@/pages/auth/signup/FormSignupStruct";
import MontaForm from "@/components/monta-form/MontaForm";
import {FetchWs} from "@/functions/FetchWs";
import {Auth} from "@/functions/Auth";

export default {
  name: 'signup',
  components: {MontaForm},
  data () {
    return {
      formSignup: {
        struct: FormSignupStruct
      }
    }
  },
  methods: {
    async submitFormSignup(data){
      const body = new FormData();
      body.append("nome", data.register_name);
      body.append("email", data.register_email);
      body.append("senha", data.register_pass);
      const response = await FetchWs('auth/register', 'POST',body);

      if(response?.success && response?.data?.id && response?.data?.jwt) {
        const $Auth = new Auth();
        await $Auth.setLogin(response.data.jwt);
        console.log('uhu')
        this.$router.push({name:'newbot-plan'})
      }
    }
  }
}
</script>

<style lang="scss">
</style>
