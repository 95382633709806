export default [
    [
        {
            col: 12,
            name: "usu_name",
            value: "",
            type: "text",
            placeholder: "Ex.: Seu nome",
            label: "Usuário",
            required: true,
            leftIcon: "person"
        }
    ],
    [
        {
            col: 12,
            name: "usu_email",
            customClass: "teste",
            value: "",
            type: "email",
            placeholder: "Ex.: nome@exemplo.com",
            label: "E-mail",
            leftIcon: "mail_outline",
            required: true
        }
    ],
    [
        {
            col: 12,
            name: "usu_grp_id",
            type: "select",
            value: "",
            options:[],
            placeholder: "Ex.: 1",
            label: "Grupo",
            leftIcon: "group",
            required: true
        }
    ]
]